<template>
  <span>
    <v-dialog v-model="dialog" max-width="600">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>{{
            activatorIcon ? activatorIcon : "mdi-folder-open-outline"
          }}</v-icon>
        </v-btn>
      </template>

      <v-card width="100%" class="mx-auto pa-5">
        <v-card-title>
          <span>Load Map</span>
          <v-spacer />
          <v-btn @click="dialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedMap"
            :items="maps"
            label="Choose a Map"
            item-text="name"
            item-value="id"
            return-object
            clearable
          />

        </v-card-text>
        <v-card-actions class="justify-center">
                    <v-btn @click="chooseMap">Start</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
/**
 * @activatorIcon - controls activating icon.  use mdi-icon-name
 * @callbackProps - properties to pass back on emit event 'selectedMap'
 */

/**
 * Events:
 * selectedMap - when a map is selected, the selected map will be passed through the argument
 * onOpen - when the dialog opens
 */
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["activatorIcon", "callbackProps"],
  data() {
    return {
      dialog: false,
      maps: []
    };
  },
  watch: {
    dialog(show) {
      if (show) {
        this.openDialog();
      }
    },
  },
  computed: {},
  methods: {
    ...mapActions("messages", ["addErrorMessage", "addMessage"]),
    openDialog(event) {
      this.$emit("onOpen", event);
    },
    fetchMaps(){
      const params = {page: 0, size: 1000, sort: 'name'}
      this.$axios.get('/map', {params})
        .then((response) => {
          this.maps = response.data.content;
        })
        .catch(() => {
          this.addErrorMessage("Error retrieving available maps")
        })
    },
    chooseMap(){
      this.$emit('input', this.selectedMap)
      this.$emit('change', this.selectedMap)
      this.dialog = false;
    }
  },
  mounted() {
    this.fetchMaps();
  }
};
</script>